"use client";

import { usePathname } from "next/navigation";
import Script from "next/script";
import { useEffect, useRef } from "react";

export const Analytics = () => {
	const pathName = usePathname();

	const firstRenderRef = useRef(false);

	useEffect(() => {
		if (firstRenderRef.current) {
			window._paq?.push(["setDocumentTitle", document.title]);
			window._paq?.push(["setCustomUrl", window.location.href]);
			window._paq?.push(["trackPageView"]);
		} else {
			firstRenderRef.current = true;
		}
	}, [pathName]);

	return (
		<Script id="matomo">{`
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      _paq.push(['enableHeartBeatTimer', 5]);
      (function() {
        var u="//piwik.peerigon.com/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '5']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
  `}</Script>
	);
};
